import { ChevronLeftIcon, SearchIcon } from '@c/icons';
import { ChevronDown } from '@c/icons/chevron';
import { ChangeEvent, KeyboardEventHandler, useRef } from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import Button from '@ui/Button';

export const InputStyles = cva(
  'flex flex-row items-center justify-between grow rounded-[1rem] gap-[0.8rem] relative',
  {
    variants: {
      type: {
        search: 'px-[1rem] py-[1.2rem] max-h-[4.4rem]',
        tel: 'px-[2.4rem] py-[1rem]',
        email: 'px-[2.4rem] py-[1rem]',
        password: 'px-[2.4rem] py-[1rem]',
        text: 'px-[2.4rem] py-[1rem]',
        number: 'px-[2.4rem] py-[1rem]',
        date: 'px-[2.4rem] py-[1rem] text-[1.6rem]',
        noPadding: '',
      },
      stripe: { true: '!px-[1rem] py-[.9rem] text-[#30313e] text[14.88px]' },
      onDark: {
        true: 'bg-brand-white focus-within:bg-brand-white',
        false: 'bg-[#F3F4F6]',
      },
      full: {
        true: 'w-full max-w-full',
      },
      focus: {
        default:
          'focus-within:outline focus-within:outline-2 focus-within:outline-brand-secondary focus-within:bg-transparent',
      },
      error: {
        true: 'outline outline-2 outline-brand-red focus-within:outline-brand-red',
      },
      disabled: {
        true: 'bg-brand-gray-light text-brand-gray select-none focus-within:outline-none cursor-not-allowed opacity-50',
      },
      readonly: {
        true: 'select-none focus-within:outline-none cursor-pointer',
      },
      bordered: {
        true: 'border border-[#E5E7EB]',
      },
    },
    defaultVariants: {
      type: 'text',
      onDark: false,
    },
  }
);

interface Props extends VariantProps<typeof InputStyles> {
  id?: string;
  placeholder: string;
  showCloseIcon?: boolean;
  hideSearchIcon?: boolean;
  onClose?: () => void;
  onDark?: boolean;
  type: 'tel' | 'search' | 'email' | 'text';
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onClick?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  center?: boolean;
  initialValue?: string;
  value?: string;
  trailingIconButton?: React.ReactNode;
  disabled?: boolean;
  bordered?: boolean;
  goBack?: () => void;
  searchIconRight?: boolean;
  showClearIcon?: boolean;
  stripe?: boolean;
  autoComplete?: string;
  white?: boolean;
}

const Input = ({
  id,
  placeholder,
  type,
  onDark,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  focus,
  error,
  center,
  full,
  initialValue,
  value,
  trailingIconButton,
  disabled,
  bordered,
  goBack,
  searchIconRight,
  readonly,
  stripe,
  autoComplete,
  white,
  hideSearchIcon
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const AreaCodeSelect = () => (
    <div className="mr-[1.6rem] flex flex-row items-center gap-[0.8rem]">
      <span className="text-input font-semibold text-brand-white">+1</span>
      <ChevronDown />
    </div>
  );

  return (
    <div
      className={InputStyles({
        type,
        onDark,
        focus,
        error,
        full,
        disabled,
        bordered,
        stripe,
      }) + `${white ? 'bg-white border-black' : ''}`}
    >
      {goBack && (
        <Button
          leadingIcon={<ChevronLeftIcon />}
          type="text"
          width="small"
          noXPadding
          onClick={goBack}
        />
      )}
      {type === 'search' && !searchIconRight && !hideSearchIcon && <SearchIcon />}
      {type === 'tel' && <AreaCodeSelect />}

      <input
        ref={ref}
        className={`w-full border-none border-transparent bg-transparent text-[1.6rem] leading-input focus:outline-none focus:ring-0 sm:text-input ${
          center ? 'text-center' : ''
        } placeholder:text-[1.4rem]`} 
        value={value}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        defaultValue={initialValue}
        disabled={!!disabled}
        readOnly={!!readonly}
        autoComplete={autoComplete}
        id={id || ''}
      />

      {trailingIconButton}
      {type === 'search' && searchIconRight && !hideSearchIcon && (
        <>
          {trailingIconButton && <div className="h-[2rem] w-[2px] bg-black" />}
          <SearchIcon />
        </>
      )}
    </div>
  );
};

export default Input;
